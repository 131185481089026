import path from 'path'

import { HtmlRenderer, Parser } from 'commonmark'
import escape from 'lodash.escape'
import isEqual from 'lodash.isequal'
import { mapGetters } from 'vuex'

import { version } from '../../package.json'
import { getCatalogUrl, backends } from '../util'
import User, { PlatformUser } from '../models/user'

const BAND_LABELS = {
  id: 'ID',
  name: 'Name',
  common_name: 'Common Name',
  gsd: `GSD (m)`,
  accuracy: 'Accuracy (m)',
  center_wavelength: 'Center Wavelength (μm)',
  full_width_half_max: `FWHM (μm)`,
  description: 'Description'
}

const MARKDOWN_READER = new Parser({
  smart: true
})
const MARKDOWN_WRITER = new HtmlRenderer({
  safe: true,
  softbreak: '<br />'
})

export default {
  metaInfo() {
    return {
      script: [
        { json: this.jsonLD, type: 'application/ld+json' },
        {
          json: {
            path: this.path
          },
          class: 'state',
          type: 'application/json'
        }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      title: this.title,
      titleTemplate: 'Phoenix Catalog'
    }
  },
  computed: {
    ...mapGetters(['getEntity']),
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
    browserVersion() {
      return version
    },
    assets() {
      if (!this.entity.assets) return []
      return (
        Object.keys(this.entity.assets.paths)
          .map((key) => {
            if (typeof this.entity.assets[key] === 'object') {
              return {
                ...this.entity.assets[key],
                key
              }
            } else {
              return {
                ...this.entity.assets[key],
                key
              }
            }
          })
          .map((x) => {
            const bands = Object.keys(this.entity.assets.paths)
            return {
              ...x,
              bands,
              title: x.title || path.basename(x.href),
              label:
                escape(x.title) || this.entity.assets.paths[x.key] || `<code>${escape(path.basename(x.href))}</code>`,
              href: x.href // todo
            }
          })
          .map((x) => {
            return {
              ...x,
              roleNames: x.roles ? x.roles.filter((x) => x != null).join(', ') : null,
              bandNames: (x.bands ? x.bands : [])
                .map((band) => (band != null ? band.description || band.common_name || band.name : null))
                .filter((x) => x != null)
                .join(', ')
            }
          })
          // prioritize assets w/ a format set
          .sort((a, b) => {
            const formatA = a.format || 'zzz'
            const formatB = b.format || 'zzz'

            if (formatA < formatB) {
              return -1
            }

            if (formatA > formatB) {
              return 1
            }

            return 0
          })
      )
    },
    bands() {
      return [] // Overwritten in Item.
    },
    hasBands() {
      return this.bands.length > 0 || this.assets.some((asset) => asset.bands.length > 0)
    },
    bandFields() {
      if (!this.bands) return []

      const example = this.bands[0]

      if (example != null) {
        return Object.keys(example).map((k) => ({
          key: k,
          label: BAND_LABELS[k]
        }))
      }

      return []
    },
    breadcrumbs() {
      // create bread crumbs
      // home -> collection -> item
      const crumbs = [
        {
          to: '/',
          text: 'Catalog'
        }
      ]

      if (this.collectionID) {
        const catalog = `${this.endpoint}/collections/${this.collectionID}`
        const entity = this.getEntity(catalog)
        crumbs.push({
          text: entity.title,
          to: `/browse/collections/${this.collectionID}`
        })
      }

      if (this.itemID) {
        crumbs.push({
          text: this.itemID,
          to: `/browse/collections/${this.collectionID}/items/${this.itemID}`
        })
      }
      return crumbs
    },
    description() {
      return this._description && MARKDOWN_WRITER.render(MARKDOWN_READER.parse(this._description))
    },
    errored() {
      return this._entity instanceof Error
    },
    entity() {
      if (this.errored) {
        return {}
      }
      return this._entity || {}
    },
    id() {
      // REQUIRED
      return this.entity.id
    },
    keywords() {
      if (Array.isArray(this.entity.keywords)) {
        return this.entity.keywords
      } else if (this.rootCatalog && Array.isArray(this.rootCatalog.keywords)) {
        return this.rootCatalog.keywords
      }
      return []
    },
    license() {
      if (this.licenseUrl) {
        return `<a href="${this.licenseUrl}" target="_blank">${this._license}</a>`
      }

      return this._license
    },
    licenseUrl() {
      if (
        typeof this._license === 'string' &&
        this._license !== 'proprietary' &&
        this._license !== 'various' &&
        this._license.match(/^[\w\-.+]+$/i)
      ) {
        // regexp from STAC json schemas
        return `https://spdx.org/licenses/${this._license}.html`
      }

      return this.links
        .concat(
          ((this.collection && this.collection.links) || []).concat((this.rootCatalog && this.rootCatalog.links) || [])
        )
        .filter((x) => x.rel === 'license')
        .map((x) => x.href)
        .pop()
    },
    links() {
      return this.entity.links || []
    },
    sources() {
      return this.entity.sources || []
    },
    shownLinks() {
      const ignoreRels = ['self', 'parent', 'child', 'item', 'collection', 'root', 'data', 'items', 'items_at']
      return this.links.filter((link) => !ignoreRels.includes(link.rel))
    },
    loaded() {
      return Object.keys(this.entity).length > 0
    },
    providers() {
      return this.entity.providers || (this.rootCatalog && this.rootCatalog.providers) || []
    },
    rootCatalog() {
      return this.getEntity(this.ancestors[0])
    },
    thumbnail() {
      let thumbnail = this.assets.find((x) => x.key === 'thumbnail')
      if (!thumbnail) {
        thumbnail = this.links.find((x) => x.rel === 'preview')
      }

      return null
    },
    title() {
      if (this._title != null) {
        return `${this._title} (${this.id})`
      }

      return this.id
    },
    prod() {
      return window.location.hostname.includes('prod')
    }
  },
  watch: {
    $route(to, from) {
      if (!isEqual(to.query, from.query)) {
        // creates an error as it is not defined
        // this.syncWithQueryState(to.query)
      }
    },
    entity(to, from) {
      if (!isEqual(to, from)) {
        this.initialize()
      }
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async updateState(updated) {
      const qs = {
        ...this.$route.query,
        ...updated
      }

      // remove nulls and false values
      const query = Object.keys(qs)
        .filter((x) => qs[x] != null && qs[x] !== false)
        .reduce((acc, k) => {
          acc[k] = qs[k].toString()

          return acc
        }, {})

      if (isEqual(this.$route.query, query)) {
        // nothing to change
        return
      }

      try {
        await this.$router.replace({
          ...this.$route,
          query
        })
      } catch (err) {
        console.warn(err)
      }
    },
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    sortCompare(a, b, key) {
      if (key === 'link') {
        key = 'title'
      }

      if (a[key] == null) {
        return -1
      } else if (b[key] === null) {
        return 1
      } else if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        // If both compared fields are native numbers
        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0
      } else {
        // Stringify the field data and use String.localeCompare
        return a[key].toString().localeCompare(b[key].toString(), undefined, {
          numeric: true
        })
      }
    },
    async selectEnv(env) {
      if (env === this.getCurrentEnv()) {
        return
      }

      // if platform auth disconnect as new auth is required
      const userObject = User.loadUser()
      if (userObject instanceof PlatformUser) {
        this.logout()
      }

      const backend = backends.find((backend) => {
        return backend.env === env
      })

      if (backend.env === 'catalog production') {
        const query = Object.assign({}, this.$route.query)
        delete query.endpoint
        this.$router.replace({ query }).then(() => location.reload())
        return
      }

      this.$router.push({ query: { endpoint: encodeURIComponent(backend.endpoint) } }).then(() => location.reload())
    },
    openAPI() {
      const endpoint = getCatalogUrl(this.$route)
      const u = new URL(window.location.href)
      u.searchParams.delete('endpoint')

      const currentBase = window.location.origin
      const prefix = '/browse'
      const url = u.toString().substring(currentBase.length + prefix.length)
      window.open(endpoint + url)
    },
    getCurrentEnv() {
      const endpoint = this.$route.query.endpoint
      if (!endpoint) {
        return 'catalog production'
      }
      const url = decodeURIComponent(endpoint)
      const backend = backends.find((backend) => {
        return backend.endpoint === url
      })
      return backend.env
    },
    roundFloat(floatValue, precision = 2) {
      if (typeof floatValue === 'number') {
        return floatValue.toFixed(precision)
      }
      return floatValue
    },
    formatNumber(x) {
      return new Intl.NumberFormat('fr-FR').format(x)
    }
  }
}
